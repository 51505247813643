.cms-partnerlist,
.cms-clerk,
.cms-productlist {
  border-radius: 0;
}

.cms-faqblock {
  .faq__icon {
    border-radius: 0;
    color: $color2;
  }
}
