.product-card {
  &__read-more {
    &__arrow {
      svg {
        fill: $color3;
      }
    }
  }
  &__thumbnail {
    border-radius: 0;
    svg {
      fill: $color4;
    }
    &:hover {
      border-color: $color3;
    }
    &__active {
      box-shadow: 0 0 0 1px $color3;
      border-color: $color3;
    }
  }

  &__volume-discount,
  &__part-of-bundle {
    &__tag {
      border-color: transparent;
      background-color: $color3;
      color: $color5;
      border: none;
    }
  }

  &__part-of-bundle__tag,
  &__volume-discount__tag {
    background-color: var(--brand-color);
    color: var(--color-on-brand-color);
  }
  &__extraordinary-savings,
  &__current-price,
  &__alternative-product__info__price {
    color: var(--primary-color);
    border-radius: var(--rounded-corners);
    font-weight: var(--brand-font-weight);
  }
  &__extraordinary-savings {
    color: $color5;
    background-color: $color4;
    .product-card__savings {
      border-right: none;
      border-top: none;
      border-bottom: none;

      background-color: $color4;
      color: $color5;
      margin: 1px 0 1px 10px;
      padding: 0px 10px 0px 12px;
    }
  }
  &__offer-expiry {
    @media (--tablet-and-above) {
      font-size: 14px;
    }
    text-transform: initial;
  }
  &__stock__message {
    background-color: $color8;
  }

  &__variants {
    &__price {
      color: $color3;
    }
    &__image-variant {
      img {
        border-radius: 0 !important;
      }
    }
    &__savings {
      background-color: var(--campaign-color);
      color: var(--color-on-campaign-color);
    }
  }
  &__CTA {
    &__add-to-basket {
      border-radius: 0;

      &:hover {
        background-color: $color1;
        color: $color3;

      }
      span {
        font-weight: 700;
      }
    }
  }

  &__stock {
    &__status {
      &--in-stock {
        background-color: #29bf12;
        box-shadow: 0 0 0 3px rgba(41, 191, 18, 0.2);
        @media (--mobile) {
          background-color: #29bf12;
          box-shadow: 0 0 0 2px rgba(41, 191, 18, 0.2);
        }
      }
    }
  }
  &__alternative-product {
    &__CTA {
      &:hover {
        background-color: $color10;
      }
    }
    &__wrapper {
      color: $color3;
    }
  }
  &__additional-info {
    a {
      color: $color3;
      svg {
        fill: $color3;
      }
      &:hover {
        color: $color4;
        svg {
          fill: $color4;
        }
      }
    }
  }

  &__enlarged {
    &__zoom {
      &__in,
      &__out {
        background-color: $gColorbg3;
        border-radius: 0;
        &:hover {
          background-color: $color1;
        }
      }
    }
  }
  &__content {
    &__header {
      &__h2 {
        color: $color3;
      }
      &__h3 {
        display: none;
      }
    }
    &__toggle {
      fill: $color3;
    }
    &__centered {
      &__h2 {
        color: $color3;
      }
    }
    &__wrapper,
    &__others-are-looking-at,
    &__explore-more,
    &__iu {
      background-color: $color5;
      @media only screen and (min-width: 961px) {
        border-bottom: var(--border-thin) $gColorBorder1;
      }
    }

    &__product-serie {
      @media only screen and (min-width: 961px) {
        h2,
        h3 {
          color: $color3;
        }
      }
      .productbox__list__nav {
        border-color: $color3;
        svg {
          fill: $color3;
        }
        &:hover {
          background-color: $color3;
          border-color: $color3;
        }
      }
      .none-active {
        &:hover {
          background-color: transparent;
          svg {
            fill: $color3;
          }
        }
      }
    }

    &__body {
      &__blockquote {
        svg {
          fill: $color3;
        }
      }

      &__centered {
        &__h2 {
          color: $color3;
        }
        li {
          color: $color3;
        }
      }
    }
    &__manufacturer {
      @media only screen and (min-width: 961px) {
        color: $color3;
        blockquote {
          color: $color3;
          .product-card__content__body__blockquote {
            background-color: $color3;
            svg {
              fill: $color1;
            }
          }
        }
        .product-card__read-more {
          &:hover {
            .product-card__read-more__arrow {
              background-color: $color3;
            }
          }
          &__arrow {
            border-color: $color3;
            box-shadow: 0 0 0 1px $color3;
            svg {
              fill: $color3;
            }
          }
        }
      }
      blockquote svg {
        fill: $color3;
      }
      .product-card__read-more__arrow {
        svg {
          fill: $color3;
        }
      }
    }
    &__iu {
      &__cta__arrow {
        span {
          color: $color5;
        }
        &:hover {
          span {
            color: $color3;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__maininfo {
    .drop-down__see-more-header {
      border-color: $color1 !important;
      border-radius: 0;
      color: $color2 !important;
      background-color: $color1 !important;
    }
    .drop-down__open {
      .drop-down__see-more-header {
        border-color: $color1 !important;
        border-radius: 0;
        color: $color2 !important;
        background-color: $color1 !important;
      }
    }
  }
}

.tag {
  &__explore-more {
    color: $color3;
    &:hover {
      color: $color3;
    }
  }
}
.drop-down__header {
  svg {
    fill: $color3;
  }
}
