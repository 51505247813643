.aa-suggestion > a {
  .algolia-autocomplete__price {
    color: var(--primary-color);
    font-weight: bold;
  }
  .splash__percentage {
    font-family: arial;
    right: -3px;
  }
}

.aa-suggestion {
  border-radius: 0;
  .algolia-autocomplete__splash {
    font-size: 12px;
    & > * {
      padding: 2px 10px !important;
    }
    @media (--mobile) {
      font-size: 12px;
    }
  }
  em {
    font-weight: 700;
    color: $color3;
  }
}

.aa-datasets-not-products {
  background-color: $color5;
  @media (--tablet-and-above) {
    border-left: var(--border-thin) var(--border);
  }

  .aa-dataset-groups,
  .aa-dataset-brands {
    .aa-suggestion {
      border: none;
    }
    @media (--mobile) {
      border-color: var(--primary-grey);
    }
  }
  .aa-dataset-groups {
    border-color: var(--primary-grey);
    @media (--tablet-and-above) {
      border-bottom: var(--border-thin) var(--border);
    }
  }

  .aa-dataset-groups,
  .aa-dataset-brands {
    .aa-suggestion > a {
      background-color: var(--brand-color);
      border: none;
      color: var(--standard-text-color);
      font-family: var(--brand-font);
      &:hover {
        background-color: var(--primary-color);
        color: var(--color-on-primary-color);
      }
    }
  }
  .aa-dataset-inspiration {
    .aa-suggestion {
      > a {
        border-color: $gColorBorder1;
        color: $color2;
        border-radius: 0;
        &:hover {
          .algolia-autocomplete__description__read-more {
            svg {
              fill: $color3;
              background-color: $color1;
              border-radius: 0;
            }
          }
        }
        .algolia-autocomplete__description {
          h4 {
            text-transform: none;
            letter-spacing: 0;
            word-spacing: 0;
          }
        }
        .algolia-autocomplete__description__read-more {
          color: $color3;
          svg {
            fill: $color5;
            background-color: $color3;
            border-radius: 0;
          }
        }
        .algolia-autocomplete__image {
          border-radius: 0;
        }
      }
    }
  }
}

.aa-suggestion > a .algolia-autocomplete__splash > * {
  border-radius: 0;
  border: none;
}
