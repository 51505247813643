// Lavprisvvs content-block styling
.contact-block {
  &--footer {
    display: none;
  }
}

.contact-block {
  &__block {
    float: left;
    margin: 0 20px;
  }
  &__title {
    font-size: 20px;
    display: inline-block;
    .nav--mobile-menu & {
      font-size: 17px;
    }
  }
  &__info {
    font-size: 13px;
    display: inline-block;
  }
  &__title,
  &__info {
    margin-left: 5px;
  }
  &__icon {
    background-color: var(--primary-color);
    color: var(--color-on-primary-color);
    fill: var(--color-on-primary-color);
    vertical-align: top;
    float: left;
  }
  &__icon-and-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__title-and-info {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    &:hover {
      .contact-block__title-and-info {
        text-decoration: underline;
      }
      .contact-block__icon {
        background-color: color-mix(in srgb, var(--primary-color), #fff 20%);
        fill: var(--color-on-primary-color);
      }
    }
  }
}

.contact-block--mobile-menu .contact-block__icon {
  fill: $colorFont1;
}
