.footer {
  background-color: var(--primary-color);
  a:focus {
    color: $color3;
  }
  &__usp,
  &__mid {
    color: $color5;
    a {
      color: $color5;
    }
  }
  &__usp {
    .container {
      border-color: #fff3;
    }
    a:focus {
      color: $color3;
    }
    &__rating--stars {
      margin-bottom: 5px;
    }
    &__item {
      background-color: var(--brand-color);
      .usp__icon {
        fill: var(--color-on-brand-color);
      }
    }
  }
  &__mid {
    padding: 40px 0 30px 0;
  }
  &__list-section {
    .heading {
      @media (--tablet-and-above) {
        border: none;
        padding: 0;
      }
    }
  }
  &__bot {
    background-color: var(--primary-color);
    padding: 9px 0 7px 0;
    border-top: var(--border-thin) #fff3;
  }

  &__list-item,
  &__nav-link,
  &__news-letter,
  .heading {
    color: $color5;
  }
  .heading--2 {
    font-size: 22px;
  }
  &__news-letter {
    border: var(--border-thin) #fff3;
    line-height: 24px;
    padding: 25px 25px 35px;
    .btn-brand {
      background-color: $color1;
      color: $color3;
      &:hover {
        background-color: $color8;
        color: $color3;
      }
    }
  }
  .btn {
    font-weight: 700;
  }

  &__logo-img {
    width: 120px;
    height: 40px;
    margin: 0 5px 0 0;
  }
}

//Mobile
@media (--mobile) {
  .footer {
    &__mid {
      padding: 0;
    }
    &__list-section {
      border-bottom: var(--border-thin) #fff3;
    }

    &__logo {
      display: block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    &__logo-img {
      margin: 0 auto 5px auto;
    }
    &__news-letter {
      padding: 5px 15px 15px;
      .heading--news-letter {
        padding: 10px 0;
      }
    }

    &__nav-icon {
      fill: $color5;
    }
  }
}
.pay-methods--footer {
  margin-bottom: 2px;
}
