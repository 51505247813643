.landingpage__campaign-banner {
  font-family: var(--button-font);
  font-size: 26px;
  @media (--tablet-lg) {
    font-size: 24px;
  }
  @media (--tablet-sm) {
    font-size: 22px;
  }
  @media (--tablet-xs) {
    font-size: 20px;
  }
  @media (--mobile) {
    font-size: 18px;
  }
  @media (--mobile-md) {
    font-size: 16px;
  }
  @media (--mobile-sm) {
    font-size: 14px;
  }
}

.embed-responsive {
  border-radius: 0;
}

.checkbox {
  &__label-style {
    border-radius: 0;
  }
  &__label-style:after {
    border: 2px solid $color3;
    background: $color1;
    border-right: none;
    border-top: none;
  }
  &__checkbox {
    &:checked + label .checkbox__label-style {
      background-color: $color1;
    }
  }
}
