// Colors
$color1: #ffd401;
$color2: #1a1a1a; //Grey
$color3: #1a1a1a; //Black
$color4: #e84e00; //Orange
$color5: #ffffff; //White
$color6: #4895b5; //hover Blue
$color7: #787979; //hover black
$color8: #fcf1bb; //secondary yellow
$color9: #fcf1bb; //new hover of main color
$color10: #e84e00; //ligher black
$color11: #e84e00; // pink for smaller icon like the search icon

// Backgrounds
$colorBg1: #ffffff; // White - Body color
$colorBg2: #ffffff; // White - Product bg
$colorBg3: #e6e6e6; // Grey - aside filters - Primary grey
$colorBg4: #444444; // Dark grey - usp header
$colorBg5: #7ea832; //btn hover gradient
$colorBg6: #ffd401; // main yellow
$colorBg7: #1a1a1a; // black
$colorBg8: #e84e00; // pink
$colorBg9: #e84e00; // main yellow
$colorBg10: #eeeeee; // Secondary grey
$colorBg11: #e6e6e6; // Tertiary grey

// Borders
$colorBorder1: #dfdddd; // Grey
$colorBorder2: #ededed; // lighter grey
$colorBorder3: #c0c0c0; // Dark grey
$colorBorder4: #dedede; // border around seach field
$colorBorder5: #cccccc; // input border
$colorBorder6: #000000; // Darkest

$colorBorder11: #fff;

$borderThin: 1px solid;
$borderMedium: 2px solid;
$borderThick: 3px solid;

// Font
$colorFont1: #444444; // primary - grey
$colorFont2: #000000; // black
$colorFont3: #ffffff; // White (productlist btn)
$colorFont4: #666666; // lightgrey - footer usp  hover on links

$fontFamilyRobotoCon: "Roboto Condensed", sans-serif;
$fontFamilyOswald: "Roboto Condensed", sans-serif;
$fontFamilyArial: Arial, sans-serif;
