.cms-halfwidth {
  .half-width {
    &__wrapper {
      &__style2 {
        .btn-whiteBorder {
          color: $color5;
          &:hover {
            color: $color3;
          }
        }
      }
    }
  }
}
