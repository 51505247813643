.usp {
  &__rating {
    position: static;
  }
  &__icon {
    fill: $colorFont3;
    &.icon {
      &--other-logo {
        width: 28px;
        position: relative;
      }
    }
  }
}
