// $product_height: 470px;
$product_height_mobile: 100%;
$product_height_slim: 374px;
$product_height_slim_mobile: 100%;
$product_height_slim_tablet: 399px;
.productlist {
  &__focus-banner {
    border-color: $color1;
  }
  &__campaign {
    background-color: $color1;
    .heading--1 {
      letter-spacing: 0;
      word-spacing: 0;
      color: $color2;
    }
    &-text {
      p {
        color: $color2;
      }
    }
  }
}

.cms-banner {
  border-radius: 0 !important;
}
.blockbanner {
  border-radius: 0 !important;
  background-color: $color1;
  @media (--tablet-and-above) {
    padding: 30px;
  }

  &__half-image {
    background-color: $color1;
    width: 50% !important;
    height: 100% !important;
    top: 0 !important;
    justify-content: flex-start;
    padding: 20px 30px;
    @media (--tablet-sm) {
      padding: 20px;
    }
    @media (--mobile) {
      padding: 15px;
    }
    .btn-whiteBorder {
      background-color: $color3;
      color: $color5;
      border: none;
      font-family: Caveat, Roboto Condensed, sans-serif;
      &:hover {
        background-color: color-mix(in srgb, $color3, #fff 20%);
        color: $color5;
      }
      &:focus {
        color: $color5;
        outline: none;
      }
    }
    .btn-cms-banner {
      font-size: 22px;
      font-family: Caveat, Roboto Condensed, sans-serif;
      padding: 8px 30px;
      @media (--tablet) {
        font-size: 20px;
      }
      @media (--mobile) {
        font-size: 18px;
      }
    }
    &__text {
      height: 49%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 860px) and (min-width: 581px) {
        height: 44%;
      }
      @media only screen and (max-width: 580px) {
        height: 50%;
      }
    }
    &__img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 125%;
      height: auto;
      @media only screen and (max-width: 860px) and (min-width: 581px) {
        width: 100%;
      }
      @media only screen and (max-width: 430px) {
        width: 164%;
      }
    }
  }
  &__full-image {
    display: flex;
    padding-top: 50px;
    @media (--tablet-lg) {
      padding-top: 60px;
    }
    @media (--tablet-sm-and-less) {
      padding-top: 40px;
    }
    @media (--mobile-md) {
      padding-top: 50px;
    }
    @media (--mobile-sm) {
      padding-top: 30px;
    }
  }
  &__item {
    position: absolute;
    width: 33%;
    bottom: 0;
    height: 60%;
    transform: scale(1);
    transform: rotate(0deg);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background-color: transparent !important;
    @media (--tablet) {
      padding: 0 10px;
      height: 68%;
    }
    img {
      max-height: 100%;
    }
  }

  &__lavpris {
    .blockbanner {
      &__half-image__text {
        .heading--2 {
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }
}

@import "_lavpris-product-stock";
