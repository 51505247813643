body {
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading,
.heading--1,
.heading--2,
.heading--3 {
  font-family: $fontFamilyRobotoCon;
  color: $color3;
  font-weight: 700;
}

a,
.link {
  color: $color2;
  text-decoration: underline;
}

input:focus {
  outline: none;
  border: var(--border-thin) $colorBorder3;
}

.divider {
  border-color: $color1;
  &--light {
    border-color: $color1;
  }
  &--dark {
    border-color: $color2;
  }
}

a:focus {
  color: $color1;
}

.bg-light-blue {
  background-color: $color8;
}

.bg-blue {
  background-color: $color3;
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%231a1a1a'/%3E%3C/svg%3E");
    border-color: $color3 !important;
  }
}

.alert.alert-info {
  background-color: $color10;
}

.drop-down {
  border-radius: 0 !important;
  &__body__item {
    .product-card__variants__image-variant__active .product-card__variants__selected {
      border-radius: 0 !important;
      top: -2px;
      right: 32px;
      left: auto;
      transform: inherit;
    }
    .product-card__variants__image-variant {
      border-radius: 0 !important;
    }
  }
  &-modal {
    .product-card__variants__selected {
      left: 0 !important;
    }
  }
}
