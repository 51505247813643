.productlist-footer {

  ul li:before {
    color: $color2;
  }
  p {
    letter-spacing: 0;
    word-spacing: 0;
  }
}

.cms-article_header {
  .article {
    &__campaign-period > * {
      background-color: $color11;
      color: $color5;
      border-radius: 0;
      &:hover {
        background-color: $color10;
      }
    }
    &__introduction {
      color: $color2;
      h1 {
        color: $color3;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        font-size: 60px;
        line-height: 70px;
        margin-bottom: 10px;
        @media (--tablet) {
          font-size: 50px;
          line-height: 60px;
        }
        @media (--mobile) {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
    &__ingress {
      font-family: $fontFamilyRobotoCon;
      color: $color3;
    }
  }
}

.cms-article_social_profile {
  .article {
    &__social-media {
      .social-follow__article {
        border-color: $color3;
        border-radius: 0;
        svg {
          fill: $color3;
        }
        &:hover {
          border-color: $color1;
          background-color: $color1;
          svg {
            fill: $color3;
          }
        }
      }
    }
    &__author img {
      border-radius: 0;
    }
  }
}

.article__content {
  p a {
    color: $color11;
    &:hover {
      color: $color10;
    }
  }
  ul li:before {
    color: $color4 !important;
  }
  .embed-responsive .youtube_codegena .play {
    background-color: $color11;
    opacity: 1;
    &:hover {
      background-color: $color11 !important;
      box-shadow: 0 0 0px 10px rgba(236, 21, 101, 0.4);
    }
  }
}

.article {
  .article-list .article-image,
  .article-list .article-list-item .a-col,
  figure {
    border-radius: 0;
  }
  .a-col .a-block h3 {
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    color: $color3;
  }

  &__factbox,
  &__guide {
    border-radius: 0;
    background-color: $color1;
    font-family: $fontFamilyRobotoCon;
    padding-top: 40px !important;

    .icon-factbox,
    .icon-guide {
      background-color: $color2;
      color: $color5;
      height: 40px;
      width: 40px;
      left: 0;
      top: 0;
    }
    h3,
    h4 {
      color: $color3;
      margin-left: 0 !important;
    }
    h4 {
      font-size: 30px;
      line-height: 40px;
      @media (--tablet) {
        font-size: 27px;
        line-height: 37px;
      }
      @media (--mobile) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__arrow {
      fill: $color4;
    }
    a {
      color: $gColorFont1;
      &:hover {
        color: $gColorFont1;
      }
 
    }
  }
  &__textbox {
    background-color: $color1;
    a {
      color: $color2;
      &:hover {
        color: color-mix(in srgb, $color2, #fff 25%);
      }
      &:focus {
        color: $color2;
      }
    }
  }
  &__textbox.bgcolor {
    background-color: $color2;
    a {
      color: $color5;
      &:hover {
        color: darken($color5, 15%);
      }
      &:focus {
        color: $color5;
      }
    }
  }
  &__blockquote {
    background-color: $color1;
    border-radius: 0;
    svg {
      fill: $color3;
    }
  }

  .faq__icon {
    border-radius: 0;
    color: $color2;
  }
  .faq__column li {
    border-radius: 0;
  }
  img {
    border-radius: 0;
  }
}

.article__highligted-text h1,
.article__highligted-text h2,
.article__highligted-text h3,
.article__highligted-text h4,
.article__highligted-text p {
  color: $color2;
}

blockquote {
  font-family: $fontFamilyRobotoCon;
}

.cms-article_step_by_step {
  .article__step-by-step li {
    background: $colorBg3;
    font-family: $fontFamilyRobotoCon;
    border-radius: 0;
  }
  .article__step-by-step li span {
    font-family: $fontFamilyRobotoCon;
  }
  .article__step-by-step li::before {
    background-color: $color1;
    color: $color3;
    font-family: $fontFamilyRobotoCon;
    border-radius: 0;
    font-weight: var(--font-weight-bold);
  }
}

.cms-article_offer_banner {
  .article__offer-banner {
    background-color: $color1;
    letter-spacing: 0;
    word-spacing: 0;
    h3,
    h2 {
      letter-spacing: 0;
      word-spacing: 0;
    }
    h2 {
      font-size: calc(2rem + 8px);
      @media (--mobile) {
        font-size: 2rem;
      }
    }
    .icon-offer {
      background-color: $color11;
      color: $color5;
      font-family: $fontFamilyRobotoCon;
      font-size: 1.6rem;
    }
    .btn {
      background-color: $color4;
      color: $color5;
      border: none;
      &:hover {
        background-color: color-mix(in srgb, $color4, #fff 10%);
        color: $color5;
      }
    }
    &__campaign-end {
      background-color: $color3;
      color: $color5;
    }
  }
}

.cms-points {
  .highlight__checkmark {
    font-weight: 700;
    svg {
      color: $color3;
    }
  }
}

.article .article-list .article-list-item {
  .read-more {
    color: $color3;
    .read-more-arrow {
      background-color: $color3;
      fill: $color5;
      border-radius: 0;
      border: none;
      svg {
        display: block;
      }
    }
  }
  &:hover {
    .read-more-arrow {
      background-color: $color1;
      fill: $color3;
      border: none;
    }
  }
}
